/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import history from '@/@history';
import * as UserActions from '@/app/auth/store/actions/user.actions';
import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import DialogScrollContainer from '@/app/customComponents/DialogScrollContainer';
import JwtService from '@/app/services/jwtService';
import { sendFCMTokenToBackend } from '@/app/services/notifications/fcmServices';
import { getCompanySchools } from '@/app/services/schools/schools';
import * as Actions from '@/app/store/actions';
import { app } from '@/utils/utils';
import { CircularProgress } from '@material-ui/core';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import axios from 'axios';
import { getMessaging, getToken } from 'firebase/messaging';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';

const messaging = getMessaging(app);

const SchoolListDialog = () => {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [page, setPage] = useState(1);
	const [fetchingMore, setFetchingMore] = useState(false);
	const [rows, setRows] = useState([]);
	const [activeId, setActiveId] = useState(null);

	useEffect(() => {
		let isMounted = true;
		setIsLoading(true);
		getCompanySchools('', '', '', 1, '', user.role[0] !== 'super_admin')
			.then((res) => {
				if (!isMounted) return;
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
				} else {
					// setHasMore(false);
				}
				setPage(res.data.current_page + 1);
				setRows(res.data.data);
				setIsLoading(false);
			})
			.catch(() => {
				if (!isMounted) return;
				setIsLoading(false);
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					}),
				);
			});

		return () => {
			isMounted = false;
		};
	}, []);

	const handleLoadMore = () => {
		setFetchingMore(true);
		getCompanySchools('', '', '', page, '', user.role[0] !== 'super_admin')
			.then((res) => {
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setPage(res.data.current_page + 1);
				setRows(rows.concat(res.data.data));
				setFetchingMore(false);
			})
			.catch(() => {
				setFetchingMore(false);
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					}),
				);
			});
	};

	const handleSubmit = (school) => {
		if (!activeId) {
			return;
		}
		setLoading(true);
		if (school.otp) {
			// dispatch(Actions.closeDialog());
			if (user.role[0] === 'super_admin') {
				secureLocalStorage.setItem('superadmin_token', JwtService.getAccessToken());
			}
			axios
				.post(`/api/v1/authenticate/school/code?is_web=1`, { code: school.otp })
				.then((resp) => {
					secureLocalStorage.setItem('view_as_id', resp?.data?.user_id);
					window.navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
						const beamsClient = new PusherPushNotifications.Client({
							instanceId: import.meta.env.VITE_BEAMS_INSTANCE_ID,
							serviceWorkerRegistration,
						});
						beamsClient.clearAllState().then(() => console.log('Cleared all beams state'));
					});
					getToken(messaging, {
						vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
					})
						.then((deviceToken) => {
							if (deviceToken) {
								sendFCMTokenToBackend(deviceToken);
							} else {
								// Show permission request UI
								console.log('No registration token available. Request permission to generate one.');
								// ...
							}
						})
						.catch((err) => {
							console.log('An error occurred while retrieving token. ', err);
							// ...
						});
					JwtService.setSession(resp.data.access_token);
					// axios.get('/api/v1/profile').then(res => {
					setLoading(false);
					JwtService.setViewAs(resp.data.access_token);
					dispatch(Actions.closeDialog());
					dispatch(
						UserActions.setUserData({
							...user,
							role: ['super_school_admin'],
							school,
						}),
					);
					history.push('/');
				})
				.catch((err) => {
					dispatch(Actions.showMessage({ variant: 'error', message: err?.response?.data?.message }));
					dispatch(Actions.closeDialog());
				});
		}
	};

	return (
		<div className="bg-white px-32 min-h-[450px] max-h-[480px] pt-[10px] min-[400px]:min-w-[370px] min-[500px]:min-w-[440px]">
			<div className="flex justify-between py-[10px] px-0 content-center items-center mx-auto font-bold w-[90%]">
				<div>
					<h1 className="text-[20px] font-bold my-[13.4px]">Select School</h1>
				</div>
				<div>
					<i
						className="fas text-[20px]"
						onClick={() => dispatch(Actions.closeDialog())}
						style={{ cursor: 'pointer' }}
					/>
				</div>
			</div>

			<DialogScrollContainer id="scrollable-list">
				{isLoading ? (
					<div className="w-1/12 mx-auto mt-16">
						<CircularProgress size={35} />
					</div>
				) : (
					rows
						?.filter((school) => school.status)
						?.map((school, i, arr) => {
							return (
								<div
									key={school.id}
									onClick={() => setActiveId(school.id)}
									style={{
										cursor: 'pointer',
										background: school.id === activeId ? '#F0F9FF' : 'white',
									}}
								>
									<div className="p-16 flex justify-between">
										<div className="w-[310px]">
											<h5 className="text-[14px]">{school.name}</h5>
										</div>
										<div
											className="rounded-full flex justify-center items-center p-4 text-white border-[1px] border-[#4DA0EE] w-[24px] h-[24px]"
											style={{ background: school.id === activeId ? '#4DA0EE' : 'white' }}
										>
											<i className="fas fa-check" />
										</div>
									</div>
									{i !== arr.length - 1 ? <hr style={{ borderColor: 'lightgray' }} /> : null}
								</div>
							);
						})
				)}
				{fetchingMore ? (
					<div className="w-1/12 mx-auto">
						<CircularProgress size={35} />
					</div>
				) : null}
			</DialogScrollContainer>
			<div className="w-full flex justify-center">
				{loading ? (
					<div className="w-1/12 mx-auto mt-24">
						<CircularProgress size={35} />
					</div>
				) : (
					<div className="mt-[25px]">
						<CustomButton
							height={38}
							onClick={() => handleSubmit(rows.filter((row) => row.id === activeId)[0])}
							type="button"
							width={156}
						>
							View School
						</CustomButton>
					</div>
				)}
			</div>
			<InfiniteScroll
				dataLength={rows.length}
				hasMore={hasMore}
				next={handleLoadMore}
				scrollableTarget="scrollable-list"
			/>
		</div>
	);
};

export default SchoolListDialog;
