import React from 'react';
import authRoles from '../../../auth/authRoles';

const SubAdminDashboardConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/home',
			// component: SubAdminDashboard
			component: React.lazy(() => import('./SubAdminDashboard')),
		},
		{
			path: '/upcoming-birthdays',
			// component: ShowAllBirthdays
			component: React.lazy(() => import('./ShowAllBirthdays')),
		},
	],
	auth: authRoles.subadmin,
};

export default SubAdminDashboardConfig;
