import NavLinkAdapter from '@/@fuse/core/NavLinkAdapter';
import { useDebounce } from '@/@fuse/hooks';
import FuseUtils from '@/@fuse/utils';
import Grow from '@material-ui/core/Grow';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Manager, Popper, Reference } from 'react-popper';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FuseNavItem from '../FuseNavItem';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.text.primary,
		'&.active, &.active:hover, &.active:focus': {
			backgroundColor: `${theme.palette.secondary.main}!important`,
			color: `${theme.palette.secondary.contrastText}!important`,
			'& .list-item-text-primary': {
				color: 'inherit',
			},
			'& .list-item-icon': {
				color: 'inherit',
			},
		},
		'& .list-item-text': {
			padding: '0 0 0 16px',
		},
		'&.level-0': {
			height: 44,
			borderRadius: 4,
			'&:hover': {
				background: 'transparent',
			},
		},
	},
	children: {},
	popper: {
		zIndex: 999,
	},
	popperClose: {
		pointerEvents: 'none',
	},
}));

const FuseNavHorizontalGroup = (props) => {
	const userRole = useSelector(({ auth }) => auth.user.role);

	const classes = useStyles(props);
	const [opened, setOpened] = useState(false);
	const { item, nestedLevel, dense, location } = props;
	const theme = useTheme();
	const { t } = useTranslation('navigation');

	const handleToggle = useDebounce((open) => {
		setOpened(open);
	}, 150);

	if (!FuseUtils.hasPermission(item.auth, userRole)) {
		return null;
	}

	function isUrlInChildren(parent, url) {
		if (!parent.children) {
			return false;
		}

		for (let i = 0; i < parent.children.length; i += 1) {
			if (parent.children[i].children) {
				if (isUrlInChildren(parent.children[i], url)) {
					return true;
				}
			}

			if (parent.children[i].url === url || url.includes(parent.children[i].url)) {
				return true;
			}
		}

		return false;
	}

	return (
		<Manager>
			<Reference>
				{({ ref }) => (
					<div ref={ref}>
						<ListItem
							aria-haspopup="true"
							aria-owns={opened ? 'menu-list-grow' : null}
							button
							className={clsx(
								'list-item ',
								classes.root,
								'relative',
								`level-${nestedLevel}`,
								isUrlInChildren(item, location.pathname) && 'active',
							)}
							component={item.url ? NavLinkAdapter : 'li'}
							onMouseEnter={() => handleToggle(true)}
							onMouseLeave={() => handleToggle(false)}
							role="button"
							to={item.url}
						>
							{item.icon ? (
								<Icon className="list-item-icon text-16 shrink-0" color="action">
									{item.icon}
								</Icon>
							) : null}

							<ListItemText
								className="list-item-text"
								classes={{ primary: 'text-14' }}
								primary={item.translate ? t(item.translate) : item.title}
							/>

							{nestedLevel > 0 ? (
								<IconButton className="w-16 h-16 ltr:ml-4 rtl:mr-4 p-0" color="inherit" disableRipple>
									<Icon className="text-16 arrow-icon">
										{theme.direction === 'ltr' ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}
									</Icon>
								</IconButton>
							) : null}
						</ListItem>
					</div>
				)}
			</Reference>
			{ReactDOM.createPortal(
				<Popper
					eventsEnabled={opened}
					placement={
						nestedLevel === 0
							? theme.direction === 'ltr'
								? 'bottom-start'
								: 'bottom-end'
							: theme.direction === 'ltr'
								? 'right'
								: 'left'
					}
					positionFixed
				>
					{({ ref, style, placement }) =>
						opened && (
							<div
								className={clsx(classes.popper, { [classes.popperClose]: !opened })}
								data-placement={placement}
								ref={ref}
								style={{
									...style,
									zIndex: 999 + nestedLevel,
								}}
							>
								<Grow id="menu-list-grow" in={opened} style={{ transformOrigin: '0 0 0' }}>
									<Paper onMouseEnter={() => handleToggle(true)} onMouseLeave={() => handleToggle(false)}>
										{item.children ? (
											<ul className={clsx(classes.children, 'popper-navigation-list', dense && 'dense', 'px-0')}>
												{item.children.map((_item) => (
													<FuseNavItem
														dense={dense}
														item={_item}
														key={_item.id}
														nestedLevel={nestedLevel}
														type={`horizontal-${_item.type}`}
													/>
												))}
											</ul>
										) : null}
									</Paper>
								</Grow>
							</div>
						)
					}
				</Popper>,
				document.querySelector('#root'),
			)}
		</Manager>
	);
};

FuseNavHorizontalGroup.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.string,
		children: PropTypes.array,
	}),
};

FuseNavHorizontalGroup.defaultProps = {};

const NavHorizontalGroup = withRouter(React.memo(FuseNavHorizontalGroup));

export default NavHorizontalGroup;
