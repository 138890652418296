import { Box, Fade, Modal } from '@material-ui/core';
import React from 'react';
import { Close } from '@material-ui/icons';
import CustomButton from '../CustomButton/CustomButton';

const ModalBase = ({
	open,
	setOpen,
	showClose = true,
	children,
	maxWidth = 400,
	onClose = () => {},
	hasPadding = true,
	showX = true,
	dismissible = true,
}) => {
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		maxWidth,
		width: { xs: '90vw', sm: 400 },
		bgcolor: 'background.paper',
		borderRadius: '8px',
		boxShadow: 24,
		pt: hasPadding ? 4 : 0,
		px: hasPadding ? 4 : 0,
		pb: hasPadding ? 3 : 0,
		padding: hasPadding ? 4 : 0,
	};
	const handleCloseModal = () => {
		setOpen(false);
		onClose();
	};
	return (
		<Modal onClose={dismissible ? handleCloseModal : () => {}} open={open}>
			<Fade in={open}>
				<Box sx={style}>
					{showX ? (
						<button
							className="absolute right-0 top-0 cursor-pointer py-10 px-12"
							onClick={handleCloseModal}
							type="button"
						>
							<Close />
						</button>
					) : null}

					{children}
					{showClose ? (
						<div>
							<div className="flex justify-end mt-32">
								<CustomButton onClick={handleCloseModal}>Close</CustomButton>
							</div>
						</div>
					) : null}
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalBase;
