import * as React from 'react';
const ErrorCircleXIcon = (props) => (
	<svg fill="none" viewBox="0 0 74 74" xmlns="http://www.w3.org/2000/svg" {...props}>
		<rect fill="#DF1A1A" height="100%" rx={37} width="100%" />
		<path
			d="M37 14.5c-12.398 0-22.5 10.102-22.5 22.5S24.602 59.5 37 59.5 59.5 49.398 59.5 37 49.398 14.5 37 14.5Zm7.56 27.675a1.698 1.698 0 0 1 0 2.385 1.669 1.669 0 0 1-1.193.495c-.427 0-.854-.157-1.192-.495L37 39.385l-5.175 5.175a1.669 1.669 0 0 1-1.192.495c-.428 0-.855-.157-1.193-.495a1.698 1.698 0 0 1 0-2.385L34.615 37l-5.175-5.175a1.698 1.698 0 0 1 0-2.385 1.698 1.698 0 0 1 2.385 0L37 34.615l5.175-5.175a1.698 1.698 0 0 1 2.385 0 1.698 1.698 0 0 1 0 2.385L39.385 37l5.175 5.175Z"
			fill="#fff"
		/>
	</svg>
);
export default ErrorCircleXIcon;
