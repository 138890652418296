import Dialog from '@material-ui/core/Dialog';
import * as Actions from '@/app/store/actions';
import { useDispatch, useSelector } from 'react-redux';

const FuseDialog = () => {
	const dispatch = useDispatch();
	const state = useSelector(({ fuse }) => fuse.dialog.state);
	const options = useSelector(({ fuse }) => fuse.dialog.options);

	return (
		<Dialog
			PaperProps={{
				style: { borderRadius: '3%' },
			}}
			aria-labelledby="fuse-dialog-title"
			onClose={() => dispatch(Actions.closeDialog())}
			open={state}
			{...options}
		/>
	);
};

export default FuseDialog;
