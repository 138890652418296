import React from 'react';
import authRoles from '../../../auth/authRoles';

const StaffConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/staff',
			// component: StaffListing
			component: React.lazy(() => import('./StaffListing')),
		},
		{
			path: '/staff-editstaff/:id',
			// component: EditStaff
			component: React.lazy(() => import('./EditStaff')),
		},
		{
			path: '/staff-addstaff',
			// component: AddStaff
			component: React.lazy(() => import('./AddStaff')),
		},
		{
			path: '/staff-info/:id',
			// component: StaffInformation
			component: React.lazy(() => import('./StaffInformation')),
		},
	],
	auth: authRoles.subadmin,
};

export default StaffConfig;
