import { initializeApp } from 'firebase/app';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

/**
 * The function calculates the age difference between two dates and returns the result in years,
 * months, and days.
 * @returns The function `getAgeDetails` returns an object with the following properties:
 * - `years`: The number of years between the two dates.
 * - `months`: The number of months between the two dates.
 * - `days`: The number of days between the two dates.
 * - `allMonths`: The number of months between the two dates.
 * - `allDays`: The number of days between the two dates.
 */
const getAgeDetails = (oldDate, newDate) => {
	if (oldDate.isValid()) {
		const years = newDate.diff(oldDate, 'year');
		const allMonths = newDate.diff(oldDate, 'month');
		const months = newDate.diff(oldDate, 'month') - years * 12;
		const days = newDate.diff(oldDate.add(years, 'year').add(months, 'month'), 'day');
		return {
			years,
			months,
			allMonths,
			days,
			allDays: newDate.diff(oldDate, 'day'),
		};
	} else {
		return null;
	}
};

/**
 * The function `generateAgeString` takes a date of birth and returns a string representing the age in
 * years, months, and days.
 * @returns The function `generateAgeString` returns a string representing the age in years, months,
 * and days based on the provided date of birth (dob). If the age details are available, it returns a
 * formatted string with the number of years, months, and days. If the age details are not available,
 * it returns a dash ("-").
 */
const generateAgeString = (dob) => {
	const details = getAgeDetails(dayjs(dob), dayjs());
	if (details) {
		return `${details.years !== 0 ? `${details.years} year` : ''}${details.years > 1 ? 's' : ''}${
			details.months !== 0 ? ` ${details.months} month` : ''
		}${details.months > 1 ? 's' : ''}${` ${details.days} day`}${details.days > 1 ? 's' : ''}`;
	} else {
		return '-';
	}
};

/**
 * The scrollIntoView function scrolls the webpage to bring a specific element into view.
 */
const scrollIntoView = (id) => {
	const element = document.getElementById(id);
	if (element) {
		element.scrollIntoView();
	}
};

/* The code block is initializing a Firebase app using the provided configuration object
`firebaseConfig`. The `firebaseConfig` object contains the necessary credentials and configuration
options for connecting to a Firebase project. The `initializeApp` function is a part of the Firebase
SDK and it creates and initializes a Firebase app instance with the provided configuration. The
initialized app can then be used to access various Firebase services and features. */
const firebaseConfig = {
	apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
	authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
	projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
	storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
	appId: import.meta.env.VITE_FIREBASE_APP_ID,
	measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};
const app = initializeApp(firebaseConfig);

/**
 * The function `getImageUrl` takes a file object as input and returns a new filename with a unique
 * identifier and the same file extension.
 * @returns the filename of the image, which is a string.
 */
const getImageUrl = (file) => {
	const file_Name = file.name;
	const fileExtension = file_Name.split('.').pop();
	const uuid = uuidv4();
	const newFileName = `${uuid}.${fileExtension}`;
	const filename = `web/public/profile_images/${newFileName}`;
	return filename;
};

/**
 * The function converts a given time in 24-hour format to a 12-hour format.
 * @returns the time in 12-hour format, with the hours and minutes separated by a colon and followed by
 * either 'AM' or 'PM'.
 */
function convertTimeTo12HourFormat(time) {
	let timeArray = time.split(':');
	let hours = parseInt(timeArray[0]);
	let minutes = timeArray[1];
	let period = hours >= 12 ? 'PM' : 'AM';
	hours = hours % 12 || 12;
	return hours + ':' + minutes + ' ' + period;
}

/**
 * The function `getDayTime` returns a formatted string representing the current time or the time of
 * the previous two days.
 * @returns The function `getDayTime` returns a string representing the day and time. The format of the
 * string depends on the current date and time. If the current date is the same as the input date `e`,
 * it returns a string in the format "Today h:mm A" where h is the hour and mm is the minutes in
 * 12-hour format and A is either "AM" or "
 */
const getDayTime = (e) => {
	const now = dayjs(e);
	const yesterday = now.subtract(1, 'day');
	const dayBeforeYesterday = now.subtract(2, 'day');
	let result = '';
	if (now.isSame(dayjs(), 'day')) {
		result = 'Today ' + now.format('h:mm A');
	} else if (yesterday.isSame(dayjs(), 'day')) {
		result = 'Yesterday ' + yesterday.format('h:mm A');
	} else {
		result = dayBeforeYesterday.format('DD/MM/YY ') + dayBeforeYesterday.format('h:mm A');
	}
	return result;
};
/**
 * The function `stopBeamsClient` clears all state and interests of a Beams client if it has a device
 * ID.
 */
const stopBeamsClient = (beamsClient) => {
	if (beamsClient?._deviceId) {
		window.navigator.serviceWorker.ready.then(async () => {
			try {
				const interests = await beamsClient?.getDeviceInterests();
				if (interests) {
					await beamsClient.clearAllState();
				}
			} catch (err) {
				console.log(err);
			}
		});
	}
};

/**
 * The function calculates the number of overdue and due immunization doses for a given student based
 * on their age.
 * @returns The function `calculateDueOverDue` returns an array containing two elements: `overDuedoses`
 * and `duedoses`.
 */
const calculateDueOverDue = (student, age) => {
	const duedoses = [];
	const overDuedoses = [];

	for (const immu of student.immunizations) {
		if (immu.is_enabled === 1 && immu.is_exempted === 0) {
			let overdueCount = 0;
			let foundDueDose = false;
			let indexOfDose = 0;
			for (const dose of immu.meta) {
				indexOfDose += 1;
				const isOverdue =
					dose.max_duration < age?.allMonths && !dose.date && dose.is_recurring === '0' && dose.skip === 0;

				if (isOverdue) {
					overdueCount += 1;
				} else if (
					!foundDueDose &&
					dose.max_duration >= age?.allMonths &&
					!dose.date &&
					dose.is_recurring === '0' &&
					dose.skip === 0
				) {
					duedoses.push(
						`${immu.immunization.name.split('-')[0].split('(')[0]} dose ${indexOfDose} rec. ${dose.duration_text}`,
					);
					foundDueDose = true;
				}
			}

			if (overdueCount > 0) {
				overDuedoses.push({
					count: overdueCount,
					name: immu.immunization.name.split('-')[0].split('(')[0],
				});
			}
		}
	}
	return [overDuedoses, duedoses];
};

const isSafari = () => {
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	return isSafari;
};

const isImageFile = (file) => {
	const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
	return file && acceptedImageTypes.includes(file['type']);
};

const isInValidName = (name) => {
	const regex = /[^-,.'a-zA-Z ]/;
	return regex.test(name);
};

/**
 * The function determines if the user is viewing in standalone mode (e.g., as a PWA).
 * @returns boolean
 */
const isStandalone = () => {
	return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
};

export {
	isStandalone,
	getAgeDetails,
	scrollIntoView,
	app,
	generateAgeString,
	getImageUrl,
	convertTimeTo12HourFormat,
	getDayTime,
	calculateDueOverDue,
	stopBeamsClient,
	isSafari,
	isImageFile,
	isInValidName,
};
