import React from 'react';

const SuccessfulPageConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
			},
		},
	},
	routes: [
		{
			path: '/success',
			component: React.lazy(() => import('./SuccessfulPage')),
		},
	],
};

export default SuccessfulPageConfig;
