import React, { useState, useEffect, useContext } from 'react';
import history from '@/@history';
import { useSelector, useDispatch } from 'react-redux';
import { getNotifications, getUnreadNotifications } from '@/app/services/notifications/notifications';
import Pusher from 'pusher-js';
import * as Actions from '@/app/store/actions';
import * as userActions from '@/app/auth/store/actions';
import Calendar from './Calendar';
import Notifications from './Notifications';
import './schoolAdminTopNav.css';
import CheckinsIcon from '@/icons/TopNav/CheckinsIcon';
import { Divider } from '@material-ui/core';
import TopNavButton from '@/app/customComponents/TopNavButton';
import EventsIcon from '@/icons/TopNav/EventsIcon';
import { MaydayMessageContext } from '@/app/context/mayday-messages/MaydayMessageContext';
import { isStandalone } from '@/utils/utils';

let isLastVisitedURLMessaging = false;

const SchoolAdminTopNav = ({ setUnreadCount, unreadCount }) => {
	const dispatch = useDispatch();
	const [notifications, setNotifications] = useState([]);
	const [loadingNotifications, setLoadingNotifications] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [page, setPage] = useState(1);
	const [fetchingMore, setFetchingMore] = useState(false);
	const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
	const user = useSelector(({ auth }) => auth.user);
	const { updateNotifications, setUpdateNotifications, setMaydayMessageData, setUpdateMaydayMessages } =
		useContext(MaydayMessageContext);
	const [initialMountComplete, setInitialMountComplete] = useState(false);

	let id;
	let pusher;
	let channel;
	// used to listen for update events to refresh notifications after mayday acknowledgement
	let updateChannel;

	if (user.role[0] === 'super_admin' || user.role[0] === 'school_admin' || user.role[0] === 'sub_admin') {
		id = user.data.id;
	} else {
		id = user?.school?.admins ? user.school.admins[0].id : user?.school?.pivot?.user_id;
	}

	useEffect(() => {
		let isMounted = true;
		if (history.location.pathname === '/messaging' || history.location.pathname === '/messaging-chat') {
			isLastVisitedURLMessaging = true;
			setUnreadMessagesCount(0);
			return;
		}
		if (
			history.location.pathname !== '/messaging' &&
			history.location.pathname !== '/messaging-chat' &&
			isLastVisitedURLMessaging &&
			user
		) {
			isLastVisitedURLMessaging = false;
			getUnreadNotifications()
				.then((res) => {
					if (!isMounted) return;
					setUnreadCount(res.data.count);
					setUnreadMessagesCount(res.data.school_conversation_count);
				})
				.catch((err) => {
					if (!isMounted) return;
					console.log(err);
				});
		}

		return () => {
			isMounted = false;
		};
	}, [history.location.pathname, unreadMessagesCount]);

	const handleUpdateNotifications = () => {
		getNotifications(1)
			.then((res) => {
				setNotifications(res.data.data);
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
					setPage(2);
				} else {
					setHasMore(false);
				}
			})
			.catch((err) => console.log(err));

		// Refetch unread notifications count
		getUnreadNotifications()
			.then((res) => {
				setUnreadCount(res.data.count);
				setUnreadMessagesCount(res.data.school_conversation_count);
			})
			.catch((err) => console.log(err));

		setUpdateMaydayMessages(true);
	};

	useEffect(() => {
		if (initialMountComplete) {
			return;
		}
		let timeout;
		let isMounted = true;
		if (user) {
			getUnreadNotifications()
				.then((res) => {
					if (!isMounted) return;
					setUnreadCount(res.data.count);
					setUnreadMessagesCount(res.data.school_conversation_count);
				})
				.catch((err) => {
					if (!isMounted) return;
					console.log(err);
				});
			pusher = new Pusher(import.meta.env.VITE_PUSHER_CHANNEL_ID, {
				cluster: import.meta.env.VITE_PUSHER_CLUSTER_ID,
			});
			setLoadingNotifications(true);
			timeout = setTimeout(() => {
				getNotificationsPage1();
			}, 5000);
		}

		if (user) {
			updateChannel = pusher.subscribe(`ld-channel-${user?.data?.school.id}`);
			updateChannel.bind('ld-notifications-update-event', handleUpdateNotifications);
		}

		if (user.role[0] === 'sub_admin') {
			channel = pusher.subscribe(`ld-channel-${id}`);
			channel.bind('ld-subadmin-disabled', realtimeLogout);
		}

		if (user.role[0] !== 'super_school_admin') {
			channel = pusher.subscribe(`school_app_${user.data.school.id}`);
			channel.bind('school_disabled', realtimeLogout);
		}

		if (user.role[0] === 'school_admin' || user.role[0] === 'sub_admin') {
			channel = pusher.subscribe(`school_app_for_company${user.data.school.company_id}`);
			channel.bind('company_disabled', realtimeLogout);
		}

		navigator.serviceWorker.addEventListener('message', handleReceiveNotification);
		setInitialMountComplete(true);

		return () => {
			isMounted = false;
			pusher.disconnect();
			if (timeout) {
				clearTimeout(timeout);
			}
			navigator.serviceWorker.removeEventListener('message', handleReceiveNotification);
		};
	}, [user]);

	useEffect(() => {
		if (updateNotifications) {
			getNotifications(1)
				.then((res) => {
					setNotifications(res.data.data);
					if (res.data.last_page > res.data.current_page) {
						setHasMore(true);
						setPage(page + 1);
					} else {
						setHasMore(false);
					}
				})
				.catch((err) => console.log(err))
				.finally(() => {
					setUpdateNotifications(false);
					setLoadingNotifications(false);
				});
		}
	}, [updateNotifications]);

	const getNotificationsPage1 = () => {
		getNotifications(1)
			.then((res) => {
				setNotifications(res.data.data);
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
					setPage(page + 1);
				} else {
					setHasMore(false);
				}
			})
			.catch((err) => console.log(err))
			.finally(() => setLoadingNotifications(false));
	};

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === 'visible' && isStandalone()) {
				// Refetch notifications when brought back into focus
				handleUpdateNotifications();
			}
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);

	const realtimeLogout = () => {
		dispatch(userActions.logoutUser());
		dispatch(
			Actions.showMessage({
				message: 'Something went wrong please contact with your administrator',
				variant: 'error',
				autoHideDuration: 3000,
			}),
		);
		pusher.disconnect();
		history.push('/login');
	};
	const handleReceiveNotification = (e) => {
		if (e.data.isFirebaseMessaging && e.data.data.click_action === 'staff_attendance_push_notification') {
			// ignore duplicate msg until the firebase messaging is removed and the pusher is used
			return;
		}
		if (e.data.data.type === 'checkin_code_request' && e.data.isBg) {
			setUnreadCount(e.data.data.unread_notification_count);
			const { data } = e.data;
			const temp = {
				data: {
					title: e.data.notification.title,
					body: e.data.notification.body,
					type: e.data.data.type,
					data,
				},
				created_at: new Date().toISOString().slice(0, new Date().toISOString().length - 1),
				read_at: null,
				id: e.data?.data?.notification_id || new Date().getMilliseconds(),
			};
			setNotifications((prevState) => [temp, ...prevState]);
			return;
		}
		if (e.data.data.click_action === 'chat_silent_notification') {
			return;
		}
		if (e.data.data.click_action === 'chat_push_notification') {
			if (e.data.isFirebaseMessaging) {
				// ignore duplicate msg until the firebase messaging is removed and the pusher is used
				return;
			}
			getUnreadNotifications()
				.then((res) => {
					let src = 'assets/sounds/message-sound.mp3';
					let audio = new Audio(src);
					audio.play();
					setUnreadCount(res.data.count);
					setUnreadMessagesCount(res.data.school_conversation_count);
				})
				.catch((err) => {
					console.log(err);
				});
			return;
		}
		if (e.data.data.click_action === 'staff_attendance_push_notification') {
			if (e.data.data.school_admin_id == id) {
				setUnreadCount(e.data.data.unread_notification_count);
				const { data } = e.data;
				const temp = {
					data: {
						...data,
					},
					created_at: new Date().toISOString().slice(0, new Date().toISOString().length - 1),
					read_at: null,
					id: e.data?.data?.notification_id || new Date().getMilliseconds(),
				};
				setNotifications((prevState) => [temp, ...prevState]);
				return;
			}
		}
		if (e.data.data.type === 'teacher_announcement') {
			if (e.data.isBg && document.visibilityState === 'visible') {
				return;
			}
			if (!e.data.isBg && document.visibilityState === 'hidden') {
				return;
			}
			const roomData = JSON.parse(e.data?.data?.room);
			let src = 'assets/sounds/mayday-notification-sound.mp3';
			let audio = new Audio(src);
			audio.play();
			setUnreadCount(e.data.data.unread_notification_count);
			const temp = {
				created_at: new Date().toISOString().slice(0, new Date().toISOString().length - 1),
				read_at: null,
				auto_id: +e.data?.data?.notification_auto_id,
				data: {
					data: {
						...e.data.data,
						room: roomData,
					},
					...e.data.data,
				},
				id: e.data?.data?.notification_id || new Date().getMilliseconds(),
			};
			setMaydayMessageData((prevData) => {
				// Check if the notification with the same auto_id already exists
				if (prevData.notifications.some((notification) => notification.auto_id === temp.auto_id)) {
					return prevData;
				}
				return {
					...prevData,
					notifications: [
						{
							data: { data: { ...e.data.data, room: roomData } },
							id: e.data?.data?.notification_id || new Date().getMilliseconds(),
							auto_id: +e.data?.data?.notification_auto_id,
							created_at: new Date().toISOString(),
						},
						...prevData.notifications,
					],
				};
			});
			setNotifications((prevState) => {
				// Check if the notification with the same auto_id already exists
				if (prevState.some((notification) => notification.auto_id === temp.auto_id)) {
					return prevState;
				}
				return [temp, ...prevState];
			});
		}
	};

	const handleGoToCalendar = () => {
		history.push('/calendar');
	};
	const handleLoadMore = () => {
		setFetchingMore(true);
		getNotifications(page)
			.then((res) => {
				setNotifications([...notifications, ...res.data.data]);
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
					setPage(page + 1);
				} else {
					setHasMore(false);
				}
			})
			.catch((err) => {
				console.log({ ...err });
				setHasMore(false);
			})
			.finally(() => setFetchingMore(false));
	};

	const handleGoToCheckins = () => {
		history.push('/checkinout');
	};

	const handleGoToEvents = () => {
		history.push('/calendar-addevent');
	};

	return (
		<div className="flex items-center">
			<div className="flex items-center h-full">
				<div className="md:block hidden">
					<Calendar onClick={handleGoToCalendar} />
				</div>
				<Divider className="mx-16 my-16 hidden md:block" flexItem orientation="vertical" />
				<div className="flex gap-10">
					<Notifications
						fetchingMore={fetchingMore}
						handleLoadMore={handleLoadMore}
						hasMore={hasMore}
						loadingNotifications={loadingNotifications}
						notifications={notifications}
						setNotifications={setNotifications}
						setUnreadCount={setUnreadCount}
						setUnreadMessagesCount={setUnreadMessagesCount}
						unreadCount={unreadCount}
						unreadMessagesCount={unreadMessagesCount}
					/>
					<div className="hidden md:block flex-1">
						<TopNavButton icon={<CheckinsIcon />} onClick={handleGoToCheckins} title="Check-ins" />
					</div>
					<div className="hidden md:block">
						<TopNavButton icon={<EventsIcon />} onClick={handleGoToEvents} title="Events" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SchoolAdminTopNav;
