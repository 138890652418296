import * as React from 'react';
const NotificationsIcon = (props) => (
	<svg className="max-w-40" viewBox="0 0 40 40" width="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
		<defs>
			<linearGradient
				gradientUnits="objectBoundingBox"
				id="notificationsIcon"
				x1={0.796}
				x2={-0.11}
				y1={0.106}
				y2={1.382}
			>
				<stop offset={0} stopColor="#fff" />
				<stop offset={1} stopColor="#ffe3c3" />
			</linearGradient>
		</defs>
		<g
			style={{
				stroke: '#ff4b4b',
				strokeMiterlimit: 10,
				fill: 'url(#notificationsIcon)',
			}}
		>
			<circle
				cx={20}
				cy={20}
				r={20}
				stroke="none"
				style={{
					stroke: 'none',
				}}
			/>
			<circle
				cx={20}
				cy={20}
				r={19.5}
				style={{
					fill: 'none',
				}}
			/>
		</g>
		<path
			d="m87.9 23.894-1.19-2.234v-5.517a7.506 7.506 0 0 0-5.414-7.2v-.864a2.079 2.079 0 1 0-4.158 0v.865a7.507 7.507 0 0 0-5.414 7.2v5.516l-1.19 2.234a1.375 1.375 0 0 0 1.214 2.022h4.725a2.748 2.748 0 0 0 5.488 0h4.726a1.375 1.375 0 0 0 1.213-2.022ZM78.233 8.079a.979.979 0 1 1 1.958 0v.635a7.526 7.526 0 0 0-1.958 0Zm-5.413 8.064a6.393 6.393 0 1 1 12.786 0V21.2H72.82Zm6.393 11.267a1.651 1.651 0 0 1-1.641-1.494h3.282a1.651 1.651 0 0 1-1.641 1.494Zm7.705-2.728a.271.271 0 0 1-.236.134H71.743a.275.275 0 0 1-.243-.4l1.122-2.106H85.8l1.122 2.106a.271.271 0 0 1-.004.266Z"
			style={{
				fill: '#ff4b4b',
			}}
			transform="translate(-59.366 3)"
		/>
	</svg>
);
export default NotificationsIcon;
