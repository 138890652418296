const components = {};

export function registerComponent(name, Component) {
	components[name] = Component;
}

const FuseNavItem = ({ type, ...rest }) => {
	const C = components[type];
	return C ? <C type={type} {...rest} /> : null;
};

export default FuseNavItem;
