import Navigation from '@/app/fuse-layouts/shared-components/Navigation';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NavPwaBanner from './NavPwaBanner';
import ViewAs from './ViewAs';

const NavbarLayout1 = ({ className }) => {
	const user = useSelector(({ auth }) => auth.user);
	const [hours, setHours] = useState(new Date().getHours());
	const [minutes, setMinutes] = useState(new Date().getMinutes());

	useEffect(() => {
		const id = setInterval(() => {
			setHours(new Date().getHours());
			setMinutes(new Date().getMinutes());
		}, 1000);

		return () => {
			clearInterval(id);
		};
	}, [minutes, hours]);

	return (
		<div
			className={clsx('flex flex-col h-full min-h-full lg:min-h-auto overflow-auto mb-64 ', className)}
			style={{
				backgroundImage: 'url("assets/images/logos/nav-bar.png")',
				backgroundColor: '#346CE8',
				backgroundSize: 'cover',
			}}
		>
			<div className="text-white text-pd-base ml-[34px] mt-[30px] mb-[10px] leading-[22.4px]">
				<div className="inline-block">
					<span className="inline-block">
						{hours >= 6 && hours < 12 ? (
							<img alt="time-icon" src="assets/images/navbarIcons/Morning.png" />
						) : hours >= 12 && hours < 17 ? (
							<img alt="time-icon" src="assets/images/navbarIcons/Afternoon.png" />
						) : (
							<img alt="time-icon" src="assets/images/navbarIcons/Evening.png" />
						)}
					</span>
				</div>
				<div className="inline-block align-top ml-[8px]">
					{hours > 12
						? (hours - 12).toLocaleString('en-US', {
								minimumIntegerDigits: 2,
								useGrouping: false,
							})
						: hours.toLocaleString('en-US', {
								minimumIntegerDigits: 2,
								useGrouping: false,
							})}
					:
					{minutes.toLocaleString('en-US', {
						minimumIntegerDigits: 2,
						useGrouping: false,
					})}{' '}
					{hours >= 12 ? 'PM' : 'AM'}, <br />
					Good {hours >= 6 && hours < 12 ? 'Morning' : hours >= 12 && hours < 17 ? 'Afternoon' : 'Evening'}
				</div>
				{user.role[0] !== 'super_admin' ? (
					<p className="text-white capitalize" style={{ maxWidth: 169, margin: 0, marginTop: 31, marginBottom: 0 }}>
						{user?.school?.name || user.data?.school?.name}
					</p>
				) : null}
			</div>
			<Navigation className="pb-[20px]" layout="vertical" />

			{user.role.includes('super_admin') || user.role.includes('super_school_admin') ? (
				<div className="mb-24 pb-16">
					<ViewAs />
				</div>
			) : null}
			<NavPwaBanner />
		</div>
	);
};

export default NavbarLayout1;
