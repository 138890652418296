import React from 'react';
import authRoles from '../../../auth/authRoles';

const AdminDashboardConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/company',
			// component: AdminDashboard
			component: React.lazy(() => import('./AdminDashboard')),
		},
	],
	auth: authRoles.admin,
};

export default AdminDashboardConfig;
