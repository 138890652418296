import * as authActions from '@/app/auth/store/actions';
import CalendarIconSmall from '@/icons/TopNav/CalendarIconSmall';
import CheckinsIconSmall from '@/icons/TopNav/CheckinsIconSmall';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import {
	AccountCircleOutlined,
	AddCircleOutlineRounded,
	ExitToAppOutlined,
	KeyboardArrowDownOutlined,
} from '@material-ui/icons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const UserMenu = () => {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const [userMenu, setUserMenu] = useState(null);
	const { thumb, photo, photoURL, displayName } = user.data;

	const userMenuClick = (event) => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	const viewProfileLink = {
		route: user.role[0] === 'super_admin' ? '/superadminprofile' : '/profile-view',
	};
	return (
		<>
			<Button className="h-64 p-0 mb-4 mr-8" id="user-menu-btn" onClick={userMenuClick}>
				{thumb || photo || photoURL ? (
					<Avatar src={thumb || photo || photoURL} />
				) : (
					<Avatar className=" w-40 h-40 text-pd-sm md:text-pd-lg">{displayName[0]}</Avatar>
				)}

				<div className="hidden md:flex flex-col mx-8 items-start">
					<Typography className="normal-case font-600 flex" component="span">
						{user.data.displayName}
					</Typography>
				</div>
				<KeyboardArrowDownOutlined className="text-16" />
			</Button>

			<Popover
				anchorEl={userMenu}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				classes={{
					paper: 'py-8',
				}}
				onClose={userMenuClose}
				open={Boolean(userMenu)}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				{!user.role || user.role.length === 0 ? (
					<>
						<MenuItem component={Link} role="button" to="/login">
							<ListItemIcon className="min-w-40">
								<Icon>lock</Icon>
							</ListItemIcon>
							<ListItemText primary="Login" />
						</MenuItem>
						<MenuItem component={Link} role="button" to="/register">
							<ListItemIcon className="min-w-40">
								<Icon>person_add</Icon>
							</ListItemIcon>
							<ListItemText primary="Register" />
						</MenuItem>
					</>
				) : (
					<>
						<MenuItem
							component={Link}
							id="my-profile-btn"
							onClick={userMenuClose}
							role="button"
							to={viewProfileLink.route}
						>
							<ListItemIcon className="min-w-40">
								<AccountCircleOutlined />
							</ListItemIcon>
							<ListItemText primary="My Profile" />
						</MenuItem>
						<MenuItem
							className="md:hidden"
							component={Link}
							id="my-profile-btn"
							onClick={userMenuClose}
							role="button"
							to="/calendar"
						>
							<ListItemIcon className="min-w-40">
								<CalendarIconSmall />
							</ListItemIcon>
							<ListItemText primary="Calendar" />
						</MenuItem>
						<MenuItem
							className="md:hidden flex"
							component={Link}
							id="my-profile-btn"
							onClick={userMenuClose}
							role="button"
							to="/checkinout"
						>
							<ListItemIcon className="min-w-40">
								<CheckinsIconSmall />
							</ListItemIcon>
							<ListItemText primary="Check-ins" />
						</MenuItem>
						<MenuItem
							className="md:hidden flex"
							component={Link}
							id="my-profile-btn"
							onClick={userMenuClose}
							role="button"
							to="/calendar-addevent"
						>
							<ListItemIcon className="min-w-40">
								<AddCircleOutlineRounded />
							</ListItemIcon>
							<ListItemText primary="Events" />
						</MenuItem>
						<MenuItem
							id="logout-btn"
							onClick={() => {
								const initialState = {
									role: [], // guest
									data: {
										displayName: 'John Doe',
										photoURL: '',
										email: 'johndoe@withinpixels.com',
										shortcuts: ['calendar', 'mail', 'contacts', 'todo'],
									},
								};
								dispatch(authActions.logoutUser());
								dispatch(authActions.setUserData(initialState));
								userMenuClose();
							}}
						>
							<ListItemIcon className="min-w-40">
								<ExitToAppOutlined />
							</ListItemIcon>
							<ListItemText primary="Logout" />
						</MenuItem>
					</>
				)}
			</Popover>
		</>
	);
};

export default UserMenu;
