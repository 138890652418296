import * as React from 'react';
const EventsIcon = (props) => (
	<svg className="max-w-40" viewBox="0 0 40 40" width="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
		<defs>
			<linearGradient gradientUnits="objectBoundingBox" id="eventsIcon" x1={0.796} x2={-0.11} y1={0.106} y2={1.382}>
				<stop offset={0} stopColor="#fff" />
				<stop offset={1} stopColor="#ffcbe0" />
			</linearGradient>
			<style>{'.eventsStroke{stroke:#ff1976;fill:none;stroke-linecap:round;stroke-width:2.5px}'}</style>
		</defs>
		<g
			style={{
				strokeMiterlimit: 10,
				fill: 'url(#eventsIcon)',
				stroke: '#ff1976',
			}}
		>
			<circle
				cx={20}
				cy={20}
				r={20}
				style={{
					stroke: 'none',
				}}
			/>
			<circle
				cx={20}
				cy={20}
				r={19.5}
				style={{
					fill: 'none',
				}}
			/>
		</g>
		<path className="eventsStroke" d="M20 12.453v15.094M27.547 20H12.453" />
	</svg>
);
export default EventsIcon;
