import React, { useState } from 'react';
import { MaydayMessageContext } from './MaydayMessageContext';

const MaydayMessageProvider = ({ children }) => {
	const [maydayBarVisibility, setMaydayBarVisibility] = useState(false);
	const [maydayMessageData, setMaydayMessageData] = useState({ notifications: [], activeIndex: 0 });
	const [maydayMessageIsReading, setMaydayMessageIsReading] = useState(false);
	const [updateNotifications, setUpdateNotifications] = useState(false);
	const [updateMaydayMessages, setUpdateMaydayMessages] = useState(false);
	return (
		<MaydayMessageContext.Provider
			value={{
				maydayMessageData,
				setMaydayMessageData,
				maydayBarVisibility,
				setMaydayBarVisibility,
				maydayMessageIsReading,
				setMaydayMessageIsReading,
				updateNotifications,
				setUpdateNotifications,
				updateMaydayMessages,
				setUpdateMaydayMessages,
			}}
		>
			{children}
		</MaydayMessageContext.Provider>
	);
};

export default MaydayMessageProvider;
