import React from 'react';
import authRoles from '../../../auth/authRoles';

const MessagingConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/messaging/:newMessageToId?',
			// component: Messaging
			component: React.lazy(() => import('./Messaging')),
		},
		{
			path: '/messagingListing',
			// component: messagingListing,
			component: React.lazy(() => import('./messaging/messagingListing')),
		},
		{
			path: '/messaging-chat/:directLinkConversationId?/:directLinkStudentId?',
			// component: MessagingThread,
			component: React.lazy(() => import('./messaging/messagingThread')),
		},
	],
	auth: authRoles.subadmin,
};

export default MessagingConfig;
