import FuseUtils from '@/@fuse/utils';
import AppContext from '@/app/AppContext';
import { Component } from 'react';
import { connect } from 'react-redux';
import { matchRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';

class FuseAuthorization extends Component {
	constructor(props, context) {
		super(props);
		const { routes } = context;
		this.state = {
			accessGranted: true,
			routes,
		};
	}

	componentDidMount() {
		const { accessGranted } = this.state;
		if (!accessGranted) {
			this.redirectRoute();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		const { accessGranted } = this.state;
		return nextState.accessGranted !== accessGranted;
	}
	restrictRoutes() {
		const { history } = this.props;

		// history.goBack();
		history.push({
			pathname: '/calendar',
		});
	}
	componentDidUpdate() {
		const { accessGranted } = this.state;
		if (!accessGranted) {
			this.redirectRoute();
		}
	}

	static getDerivedStateFromProps(props, state) {
		// console.log(props, 'props');
		const { location, userRole, userPermission } = props;
		const { pathname } = location;
		// const abc = ['/login', '/register', '/forgot-password', '/reset-password'];
		const perm = [
			'/students',
			'/staff',
			'/rooms',
			'/calendar',
			'/staff-schedule',
			'/messaging',
			'/livestreaming',
			'/reports',
			'/subadmin',
		];
		const filterUser = userPermission?.map((data) => data?.split('_').join('').toLowerCase());
		const filterPerm = userRole[0] == 'sub_admin' ? perm?.filter((data) => !filterUser?.includes(data)) : [];
		const paths = state.routes.filter((data) => !filterPerm?.includes(data.path.split('-')[0]));
		const matched = matchRoutes(paths, pathname)[0];

		return {
			accessGranted: matched.route.path !== '/*' ? FuseUtils.hasPermission(matched.route.auth, userRole) : false,
		};
	}

	redirectRoute() {
		const { location, userRole, history, userPermission } = this.props;
		const { pathname } = location;
		const { routes } = this.state;
		// const permissionss = ['/rooms', '/staff', '/students'];
		// const permissionss = [];
		const perm = [
			'/students',
			'/staff',
			'/rooms',
			'/calendar',
			'/staff-schedule',
			'/messaging',
			'/livestreaming',
			'/reports',
			'/subadmin',
		];
		const filterUser = userPermission?.map((data) => data?.split('_').join('').toLowerCase());
		const filterPerm = userRole[0] == 'sub_admin' ? perm?.filter((data) => !filterUser?.includes(data)) : [];
		const paths = routes.filter((data) => !filterPerm?.includes(data.path.split('-')[0]));
		const matched = matchRoutes(paths, pathname)[0];
		/*
        User is guest
        Redirect to Login Page
        */
		if (!userRole || userRole.length === 0) {
			history.push({
				pathname: '/login',
				state: { redirectUrl: pathname },
			});
		} else {
			/*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or redirectUrl
        */
			if (['school_admin', 'sub_admin', 'super_school_admin'].includes(userRole[0])) {
				history.push({
					pathname: '/home',
				});
			}
			if (userRole[0] === 'super_admin') {
				history.push({
					pathname: '/company',
				});
			}
		}
		if (matched.route.path === '/*') {
			history.push({
				pathname: '/home',
			});
		}
	}

	render() {
		const { accessGranted } = this.state;
		const { children } = this.props;
		return accessGranted ? children : null;
	}
}

function mapStateToProps({ auth }) {
	return {
		userRole: auth.user.role,
		userPermission: auth.user.permissions,
	};
}

FuseAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps)(FuseAuthorization));
