import authRoles from '../../../auth/authRoles';
import React from 'react';

const CompanyReportsConfig = {
	settings: {
		layout: {
			Config: {},
		},
	},
	routes: [
		{
			path: '/company-reports',
			// component: CompanyReports
			component: React.lazy(() => import('./CompanyReports')),
		},
		{
			path: '/allergy-report',
			// component: AllergyReports,
			component: React.lazy(() => import('./Allergy')),
		},
		{
			path: '/ParentReports',
			// component: ParentReports,
			component: React.lazy(() => import('./ParentReports')),
		},
		{
			path: '/emergencyContact-report',
			// component: EmergencyContact,
			component: React.lazy(() => import('./EmergencyContact')),
		},
		{
			path: '/staff-report',
			// component: Staff,
			component: React.lazy(() => import('./Staff')),
		},
		{
			path: '/enrolledStudent-report',
			// component: EnrolledStudent,
			component: React.lazy(() => import('./EnrolledStudent')),
		},
		{
			path: '/schoolRoyalties-report',
			// component: SchoolRoyaltiesReport,
			component: React.lazy(() => import('./SchoolRoyaltiesReport')),
		},
		{
			path: '/immunization-report',
			// component: ImmunizationReport,
			component: React.lazy(() => import('./ImmunizationReport')),
		},
	],
	auth: authRoles.admin,
};

export default CompanyReportsConfig;
