import * as React from 'react';
const WarningCircleIcon = (props) => (
	<svg fill="none" viewBox="0 0 74 74" xmlns="http://www.w3.org/2000/svg" {...props}>
		<rect fill="#F09A18" height="100%" rx={37} width="100%" />
		<path
			d="m53.898 23.163-13.365-7.718a7.117 7.117 0 0 0-7.088 0l-13.342 7.718a7.086 7.086 0 0 0-3.533 6.142v15.39c0 2.52 1.35 4.86 3.533 6.142l13.365 7.718a7.117 7.117 0 0 0 7.087 0l13.365-7.718a7.086 7.086 0 0 0 3.533-6.142v-15.39a7.183 7.183 0 0 0-3.555-6.142Zm-18.585 4.274A1.7 1.7 0 0 1 37 25.75a1.7 1.7 0 0 1 1.688 1.688V39.25A1.7 1.7 0 0 1 37 40.938a1.7 1.7 0 0 1-1.687-1.688V27.437Zm3.757 19.98c-.112.27-.27.518-.472.743a2.227 2.227 0 0 1-2.453.473 2.323 2.323 0 0 1-.742-.473 3.287 3.287 0 0 1-.495-.742 2.218 2.218 0 0 1-.158-.855c0-.586.225-1.17.653-1.598.225-.203.45-.36.742-.473a2.247 2.247 0 0 1 2.453.473c.202.225.36.45.472.742.113.27.18.563.18.855 0 .293-.067.586-.18.855Z"
			fill="#fff"
		/>
	</svg>
);
export default WarningCircleIcon;
