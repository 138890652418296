import React from 'react';
import authRoles from '../../../auth/authRoles';

const SuperAdminProfileConfig = {
	settings: {
		layout: {
			Config: {},
		},
	},
	routes: [
		{
			path: '/superadminprofile',
			// component: adminDetail,
			component: React.lazy(() => import('@/app/fuse-layouts/shared-components/adminDetail')),
		},
		{
			path: '/Editprofile',
			// component: Editprofile,
			component: React.lazy(() => import('@/app/fuse-layouts/shared-components/Editprofile')),
		},
	],
	auth: authRoles.admin,
};

export default SuperAdminProfileConfig;
