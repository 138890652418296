import React from 'react';
import authRoles from '../../../auth/authRoles';

const CalendarConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/calendar',
			// component: CalendarView
			component: React.lazy(() => import('./CalendarView')),
		},
	],
	auth: authRoles.subadmin,
};

export default CalendarConfig;
