import React from 'react';
import authRoles from '../../../auth/authRoles';

const SchoolAdminConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/subadmin',
			// component: SchoolAdmin
			component: React.lazy(() => import('./SchoolAdmin')),
		},
		{
			path: '/subadmin-AddSchoolAdminProfile',
			// component: AddSchoolAdmin
			component: React.lazy(() => import('./addSchoolAdmin/AddSchoolAdmin')),
		},
		{
			path: '/subadmin-ViewSchoolAdminProfile/:id',
			// component: ViewSchoolAdmin
			component: React.lazy(() => import('./viewSchoolAdmin/ViewSchoolAdmin')),
		},
		{
			path: '/subadmin-EditSchoolAdminProfile/:id',
			// component: EditSchoolAdmin
			component: React.lazy(() => import('./editSchoolAdmin/EditSchoolAdmin')),
		},
	],
	auth: authRoles.subadmin,
};

export default SchoolAdminConfig;
