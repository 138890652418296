import React from 'react';
import authRoles from '../../../auth/authRoles';

const LivestreamingConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/livestreaming',
			// component: Livestreaming
			component: React.lazy(() => import('./Livestreaming')),
		},
		{
			path: '/livestreaming-CameraRegistration',
			// component: CameraRegistration
			component: React.lazy(() => import('./live/CameraRegistration')),
		},
	],
	auth: authRoles.subadmin,
};

export default LivestreamingConfig;
