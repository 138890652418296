import * as React from 'react';
const CheckinsIcon = (props) => (
	<svg className="max-w-40" viewBox="0 0 40 40" width="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
		<defs>
			<linearGradient gradientUnits="objectBoundingBox" id="checkinsIcon" x1={0.796} x2={-0.11} y1={0.106} y2={1.382}>
				<stop offset={0} stopColor="#fff" />
				<stop offset={1} stopColor="#cce0ff" />
			</linearGradient>
			<style>{'.checkinsB{fill:#6372f4}.checkinsE{fill:none}.checkinsD{stroke:none}'}</style>
		</defs>
		<g
			style={{
				strokeMiterlimit: 10,
				fill: 'url(#checkinsIcon)',
				stroke: '#6372f4',
			}}
		>
			<circle className="checkinsD" cx={20} cy={20} r={20} />
			<circle className="checkinsE" cx={20} cy={20} r={19.5} />
		</g>
		<path
			className="checkinsB"
			d="M22.802 17.259h-2.234c-.519 0-.841-.282-.835-.7s.325-.69.849-.691h4.508c.546 0 .861.276.85.728-.011.428-.314.665-.864.667-.758-.002-1.516-.004-2.274-.004ZM15.548 20.466c.544-.549 1.008-1.023 1.478-1.492.391-.389.815-.427 1.116-.108s.243.709-.133 1.088q-.914.921-1.834 1.835a.729.729 0 0 1-1.205-.007c-.4-.392-.8-.785-1.184-1.186a.715.715 0 0 1-.09-1.059c.3-.309.71-.28 1.084.1.253.247.486.52.768.829ZM15.558 24.617c.559-.569 1.044-1.079 1.549-1.569a.667.667 0 0 1 1.172.237.928.928 0 0 1-.19.716c-.638.7-1.317 1.354-1.994 2.013a.676.676 0 0 1-1.029 0q-.678-.647-1.325-1.328a.7.7 0 0 1-.022-1.029.674.674 0 0 1 1 .055c.272.272.528.566.839.905ZM15.554 16.315c.559-.567 1.045-1.076 1.549-1.565a.666.666 0 0 1 1.171.232.922.922 0 0 1-.184.716c-.637.7-1.316 1.354-1.993 2.012a.687.687 0 0 1-1.06-.013q-.662-.636-1.3-1.3a.693.693 0 1 1 .974-.982c.28.269.531.563.843.9ZM22.823 21.401h-2.274c-.5 0-.811-.268-.816-.677-.005-.431.311-.707.829-.708h4.548c.524 0 .833.267.832.7s-.307.684-.845.685h-2.274ZM22.843 25.55h-2.314c-.5 0-.8-.276-.8-.691s.312-.684.808-.685h4.587c.5 0 .8.258.813.679.01.437-.293.7-.825.7h-2.274Z"
		/>
		<g
			style={{
				strokeWidth: '1.5px',
				fill: 'none',
				stroke: '#6372f4',
			}}
			transform="translate(10.5 10.5)"
		>
			<rect className="checkinsD" height={19} rx={5} width={19} />
			<rect className="checkinsE" height={17.5} rx={4.25} width={17.5} x={0.75} y={0.75} />
		</g>
	</svg>
);
export default CheckinsIcon;
