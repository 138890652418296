import IconButton from '@material-ui/core/IconButton';
import * as Actions from '@/app/store/actions';
import React from 'react';
import { useDispatch } from 'react-redux';
import { MenuOutlined } from '@material-ui/icons';

const NavbarMobileToggleButton = ({ children, className }) => {
	const dispatch = useDispatch();

	return (
		<IconButton
			className={className}
			color="inherit"
			disableRipple
			onClick={() => dispatch(Actions.navbarToggleMobile())}
		>
			{children}
		</IconButton>
	);
};

NavbarMobileToggleButton.defaultProps = {
	children: <MenuOutlined />,
};

export default NavbarMobileToggleButton;
