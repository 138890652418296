import React from 'react';

const StudentFormConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				footer: {
					display: false,
				},
			},
		},
	},
	routes: [
		{
			path: '/studentform',
			component: React.lazy(() => import('./StudentForm')),
		},
		{
			path: '/studentformedit',
			component: React.lazy(() => import('../../../../fuse-layouts/shared-components/student-form/EditStudent')),
		},
		{
			path: '/studentcontact',
			component: React.lazy(() => import('../../../../fuse-layouts/shared-components/student-form/AddEditContact')),
		},
		{
			path: '/expiredlink',
			component: React.lazy(() => import('./ExpiredLink')),
		},
		{
			path: '/medications',
			component: React.lazy(() => import('../../../../fuse-layouts/shared-components/student-form/EditMedications')),
		},
	],
};

export default StudentFormConfig;
