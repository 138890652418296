import * as React from 'react';
const EdgeInstallIcon = (props) => (
	<svg
		aria-hidden="true"
		className="___1okpztj f1w7gpdv fez10in fg4l7m0 f16hsg94 fwpfdsa f88nxoq f1e2fz10"
		fill="currentColor"
		viewBox="0 0 20 20"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M15 3a.5.5 0 0 0-1 0v2h-2a.5.5 0 0 0 0 1h2v2a.5.5 0 0 0 1 0V6h2a.5.5 0 0 0 0-1h-2V3ZM4.5 17A1.5 1.5 0 0 1 3 15.5v-11C3 3.67 3.67 3 4.5 3H9c.83 0 1.5.67 1.5 1.5v5h5c.83 0 1.5.67 1.5 1.5v4.5c0 .83-.67 1.5-1.5 1.5h-11Zm6-6.5V16h5a.5.5 0 0 0 .5-.5V11a.5.5 0 0 0-.5-.5h-5Zm-1 5.5v-5.5H4v5a.5.5 0 0 0 .5.5h5Zm0-11.5A.5.5 0 0 0 9 4H4.5a.5.5 0 0 0-.5.5v5h5.5v-5Z" />
	</svg>
);
export default EdgeInstallIcon;
