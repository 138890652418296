import FuseNavigation from '@/@fuse/core/FuseNavigation';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';

const Navigation = ({ active, className, dense, layout }) => {
	const navigation = useSelector(({ fuse }) => fuse.navigation);

	return (
		<FuseNavigation
			active={active}
			className={clsx('navigation', className)}
			dense={dense}
			layout={layout}
			navigation={navigation}
		/>
	);
};

Navigation.defaultProps = {
	layout: 'vertical',
};

export default Navigation;
